import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled, setScrolled]);
  
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  let menu = [
    {
      path: "/#services",
      name: "Services",
      submenu: [],
      class: "nav__services",
    },
    {
      path: "/#partners",
      name: "Partners",
      submenu: [],
      class: "nav_partners",
    },
    {
      path: "/#contact",
      name: "Contact us",
      submenu: [],
      class: "nav__contact",
    },
  ];

  class Soc extends React.Component {
    render() {
      return (
      <ul className='soc__icons soc__icons--header'>
      <li className='soc__icons-title'>
      <a href='https://twitter.com/'>
          <img
              alt='Twitter'
              className='soc__icons--image'
              src='../images/twitter.svg'
          />
      </a>
      </li>
      <li className='soc__icons-title'>
      <a href='https://facebook.com/'>
          <img
              alt='Facebook'
              className='soc__icons--image'
              src='../images/facebook.svg'
          />
      </a>
      </li>
  </ul>);
    }
  }

  return (
    
    <div className={scrolled?'header liquid-alt':'header liquid'}>
    <div className="header__container container relative">
    <Link
      className="header__leftside navbar-brand"
      to="/"
    >
      <svg width="723" height="128" viewBox="0 0 723 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.1364 0C23.9632 4.45897 0 31.2682 0 63.6744C0 76.6407 3.8364 88.711 10.436 98.812C13.4469 81.2323 21.5104 65.766 30.1878 54.0976C35.713 46.6679 41.6712 40.5235 47.1242 36.1394C49.8169 33.9745 52.5307 32.1225 55.1364 30.76V0Z" fill="#49A2CB"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M118.216 98.812C124.816 88.711 128.652 76.6407 128.652 63.6744C128.652 31.2682 104.689 4.45897 73.5156 0V30.76C76.1213 32.1225 78.8351 33.9745 81.5278 36.1394C86.9808 40.5235 92.9391 46.6679 98.4642 54.0976C107.142 65.766 115.205 81.2323 118.216 98.812Z" fill="#95E28C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1162 116.152C37.6201 123.613 50.4607 128 64.3259 128C78.1911 128 91.0318 123.613 101.536 116.152C101.242 115.253 101.084 114.293 101.084 113.297C101.084 94.8866 93.1709 77.7781 83.7162 65.0644C79.0181 58.747 74.1214 53.7665 70.0117 50.4625C67.9491 48.8042 66.2311 47.6863 64.9652 47.0284C64.7125 46.8972 64.5001 46.7957 64.3259 46.7178C64.1518 46.7957 63.9393 46.8972 63.6867 47.0284C62.4208 47.6863 60.7028 48.8042 58.6401 50.4625C54.5305 53.7665 49.6337 58.747 44.9357 65.0644C35.481 77.7781 27.5683 94.8866 27.5683 113.297C27.5683 114.293 27.4097 115.253 27.1162 116.152Z" fill="#4F9F8E"/>
        <path d="M185.301 90.0824C183.585 90.0824 182.43 89.2808 181.837 87.6776L166.296 45.3093C165.872 44.1491 165.904 43.2209 166.391 42.5248C166.878 41.8287 167.736 41.4806 168.965 41.4806H175.258C177.186 41.4806 178.447 42.3982 179.04 44.2334L186.414 67.68H186.541L196.203 42.8096C196.542 41.9236 197.156 41.4806 198.046 41.4806H198.205C199.18 41.4806 199.837 41.9236 200.176 42.8096L209.901 67.68H210.028L217.465 44.2334C218.058 42.3982 219.319 41.4806 221.247 41.4806H227.381C228.61 41.4806 229.468 41.8287 229.956 42.5248C230.443 43.2209 230.475 44.1491 230.051 45.3093L214.541 87.6776C213.948 89.2808 212.783 90.0824 211.045 90.0824H210.6C208.884 90.0824 207.708 89.3019 207.072 87.7409L198.142 66.0346H198.014L189.274 87.7409C188.639 89.3019 187.463 90.0824 185.746 90.0824H185.301Z" class="go" fill="#082D3E"/>
        <path d="M246.069 49.8657C244.713 51.2157 243.039 51.8907 241.048 51.8907C239.056 51.8907 237.414 51.2263 236.121 49.8973C234.829 48.5473 234.183 46.8597 234.183 44.8346C234.183 42.9361 234.861 41.3224 236.217 39.9934C237.573 38.6645 239.236 38 241.207 38C243.219 38 244.872 38.6539 246.165 39.9618C247.478 41.2486 248.135 42.8623 248.135 44.803C248.135 46.807 247.446 48.4945 246.069 49.8657ZM238.505 90.0824C237.424 90.0824 236.598 89.7976 236.026 89.2281C235.454 88.6585 235.168 87.8358 235.168 86.76V58.7887C235.168 57.7128 235.454 56.8901 236.026 56.3206C236.598 55.751 237.424 55.4663 238.505 55.4663H243.781C244.862 55.4663 245.688 55.751 246.26 56.3206C246.853 56.8901 247.15 57.7128 247.15 58.7887V86.76C247.15 87.8358 246.853 88.6585 246.26 89.2281C245.688 89.7976 244.862 90.0824 243.781 90.0824H238.505Z" class="go" fill="#082D3E"/>
        <path d="M269.493 90.8734C265.192 90.8734 261.621 90.1351 258.782 88.6585C255.943 87.1819 254.089 85.1146 253.22 82.4567C252.86 81.402 252.977 80.5582 253.57 79.9254C254.184 79.2714 255.085 78.9445 256.271 78.9445H261.738C262.289 78.9445 262.776 79.0499 263.2 79.2609C263.624 79.4507 263.889 79.609 263.994 79.7355C264.1 79.841 264.333 80.0941 264.694 80.4949C265.732 81.7395 267.257 82.3618 269.27 82.3618C272.491 82.3618 274.101 81.4969 274.101 79.7672C274.101 78.839 273.572 78.1534 272.512 77.7104C271.453 77.2675 269.344 76.8561 266.187 76.4764C261.738 75.9491 258.464 74.8416 256.367 73.154C254.269 71.4665 253.22 69.1039 253.22 66.0663C253.22 62.6279 254.672 59.875 257.574 57.8078C260.477 55.7194 264.333 54.6752 269.143 54.6752C273.148 54.6752 276.474 55.3819 279.123 56.7952C281.771 58.1875 283.509 60.1598 284.335 62.7122C284.695 63.767 284.579 64.6213 283.986 65.2752C283.392 65.9081 282.502 66.2245 281.316 66.2245H275.881C275.627 66.2245 275.394 66.2139 275.182 66.1928C274.991 66.1717 274.811 66.1296 274.642 66.0663C274.472 65.9819 274.324 65.9186 274.197 65.8764C274.091 65.8131 273.964 65.7182 273.815 65.5916C273.688 65.4651 273.603 65.3807 273.561 65.3385C273.519 65.2963 273.423 65.1908 273.275 65.0221C273.148 64.8533 273.074 64.7584 273.052 64.7373C272.141 63.7037 270.859 63.1869 269.207 63.1869C266.495 63.1869 265.139 63.9674 265.139 65.5284C265.139 66.3721 265.711 67.005 266.855 67.4269C268.02 67.8488 270.34 68.2706 273.815 68.6925C278.074 69.1988 281.199 70.3168 283.191 72.0466C285.204 73.7763 286.21 76.2127 286.21 79.3558C286.21 82.8153 284.685 85.5998 281.634 87.7093C278.583 89.8187 274.536 90.8734 269.493 90.8734Z" class="go" fill="#082D3E"/>
        <path d="M295.014 90.0824C293.933 90.0824 293.107 89.7976 292.535 89.2281C291.963 88.6585 291.677 87.8358 291.677 86.76V44.803C291.677 43.7272 291.963 42.9045 292.535 42.3349C293.107 41.7654 293.933 41.4806 295.014 41.4806H300.29C301.37 41.4806 302.197 41.7654 302.769 42.3349C303.362 42.9045 303.659 43.7272 303.659 44.803V55.4663L303.373 59.7379H303.849C306.138 56.2784 309.634 54.5487 314.338 54.5487C318.448 54.5487 321.626 55.8143 323.872 58.3457C326.139 60.877 327.273 64.442 327.273 69.0406V86.76C327.273 87.8358 326.987 88.6585 326.415 89.2281C325.843 89.7976 325.016 90.0824 323.936 90.0824H318.628C317.548 90.0824 316.721 89.7976 316.149 89.2281C315.577 88.6585 315.291 87.8358 315.291 86.76V70.0215C315.291 66.0557 313.649 64.0728 310.365 64.0728C308.182 64.0728 306.519 64.8744 305.375 66.4776C304.231 68.0597 303.659 70.359 303.659 73.3755V86.76C303.659 87.8358 303.362 88.6585 302.769 89.2281C302.197 89.7976 301.37 90.0824 300.29 90.0824H295.014Z" class="go" fill="#082D3E"/>
        <path d="M356.958 91C354.648 91 352.593 90.5148 350.792 89.5445C348.991 88.553 347.603 87.1502 346.628 85.3361H346.152L345.548 87.3296C344.955 89.1648 343.694 90.0824 341.766 90.0824H338.429C337.348 90.0824 336.522 89.7976 335.95 89.2281C335.378 88.6585 335.091 87.8358 335.091 86.76V44.803C335.091 43.7272 335.378 42.9045 335.95 42.3349C336.522 41.7654 337.348 41.4806 338.429 41.4806H343.704C344.785 41.4806 345.611 41.7654 346.184 42.3349C346.777 42.9045 347.073 43.7272 347.073 44.803V55.4663L346.787 59.3582H347.264C348.175 57.8183 349.468 56.637 351.142 55.8143C352.837 54.9705 354.849 54.5487 357.18 54.5487C361.799 54.5487 365.518 56.194 368.336 59.4848C371.175 62.7755 372.595 67.1315 372.595 72.5528C372.595 78.1007 371.164 82.5622 368.304 85.9373C365.465 89.3124 361.683 91 356.958 91ZM353.589 81.4125C358.187 81.4125 360.486 78.4593 360.486 72.5528C360.486 69.8738 359.882 67.8066 358.674 66.351C357.466 64.8744 355.729 64.1361 353.462 64.1361C351.364 64.1361 349.69 64.9061 348.44 66.446C347.211 67.9648 346.597 70.0004 346.597 72.5528C346.597 75.2951 347.222 77.4573 348.472 79.0394C349.743 80.6215 351.449 81.4125 353.589 81.4125Z" class="go" fill="#082D3E"/>
        <path d="M395.986 90.8734C390.096 90.8734 385.403 89.2281 381.907 85.9373C378.432 82.6255 376.695 78.164 376.695 72.5528C376.695 67.2159 378.485 62.9021 382.066 59.6113C385.668 56.3206 390.361 54.6752 396.145 54.6752C402.014 54.6752 406.686 56.2995 410.161 59.5481C413.657 62.7966 415.405 67.1315 415.405 72.5528C415.405 78.0585 413.615 82.4884 410.034 85.8424C406.475 89.1964 401.792 90.8734 395.986 90.8734ZM395.986 81.4125C398.338 81.4125 400.139 80.6637 401.389 79.166C402.661 77.6472 403.296 75.4428 403.296 72.5528C403.296 69.9793 402.661 67.9331 401.389 66.4143C400.118 64.8955 398.37 64.1361 396.145 64.1361C393.815 64.1361 392.003 64.8639 390.711 66.3194C389.439 67.7749 388.804 69.8527 388.804 72.5528C388.804 75.3373 389.439 77.51 390.711 79.071C391.982 80.632 393.74 81.4125 395.986 81.4125Z" class="go" fill="#082D3E"/>
        <path d="M424.877 90.0824C423.796 90.0824 422.97 89.7976 422.397 89.2281C421.825 88.6585 421.539 87.8358 421.539 86.76V58.7887C421.539 57.7128 421.825 56.8901 422.397 56.3206C422.97 55.751 423.796 55.4663 424.877 55.4663H428.214C430.184 55.4663 431.392 56.2362 431.837 57.7761L432.6 60.2125H433.076C435.577 56.4366 439.284 54.5487 444.2 54.5487C448.311 54.5487 451.489 55.8143 453.735 58.3457C456.002 60.877 457.136 64.442 457.136 69.0406V86.76C457.136 87.8358 456.85 88.6585 456.277 89.2281C455.705 89.7976 454.879 90.0824 453.798 90.0824H448.491C447.41 90.0824 446.584 89.7976 446.012 89.2281C445.44 88.6585 445.154 87.8358 445.154 86.76V70.0215C445.154 66.0557 443.512 64.0728 440.227 64.0728C438.045 64.0728 436.382 64.8744 435.238 66.4776C434.093 68.0597 433.521 70.359 433.521 73.3755V86.76C433.521 87.8358 433.225 88.6585 432.631 89.2281C432.059 89.7976 431.233 90.0824 430.152 90.0824H424.877Z" class="go" fill="#082D3E"/>
        <path d="M499.406 72.5845C499.406 73.597 499.12 74.3775 498.548 74.926C497.976 75.4533 497.149 75.717 496.069 75.717H474.965C475.559 80.1469 477.709 82.3618 481.417 82.3618C483.324 82.3618 484.924 81.6446 486.216 80.2101C487.149 79.1976 488.25 78.6913 489.522 78.6913H494.734C495.963 78.6913 496.874 78.9867 497.467 79.5773C498.061 80.1469 498.177 80.8957 497.817 81.8239C496.694 84.7349 494.723 86.9709 491.905 88.5319C489.109 90.0929 485.655 90.8734 481.544 90.8734C475.887 90.8734 471.332 89.207 467.878 85.874C464.445 82.5411 462.729 78.1112 462.729 72.5845C462.729 67.2054 464.435 62.881 467.846 59.6113C471.257 56.3206 475.771 54.6752 481.385 54.6752C486.81 54.6752 491.164 56.3206 494.448 59.6113C497.753 62.881 499.406 67.2054 499.406 72.5845ZM475.156 68.5976H487.361C487.149 66.8679 486.524 65.5389 485.485 64.6107C484.468 63.6615 483.112 63.1869 481.417 63.1869C478.027 63.1869 475.94 64.9904 475.156 68.5976Z" class="go" fill="#082D3E"/>
        <path d="M525.467 90.0824C524.302 90.0824 523.455 89.7554 522.925 89.1015C522.395 88.4476 522.257 87.551 522.512 86.4119L531.951 44.2018C532.354 42.3877 533.466 41.4806 535.288 41.4806H535.606C537.322 41.4806 538.541 42.2505 539.261 43.7904L551.37 70.0848H551.529L563.606 43.7904C564.327 42.2505 565.545 41.4806 567.261 41.4806H567.579C569.38 41.4806 570.492 42.3877 570.916 44.2018L580.356 86.4119C580.61 87.551 580.472 88.4476 579.942 89.1015C579.413 89.7554 578.565 90.0824 577.4 90.0824H571.488C569.454 90.0824 568.236 89.0909 567.833 87.1081L563.988 67.7433H563.861L553.499 88.8167C553.076 89.6605 552.408 90.0824 551.497 90.0824H551.37C550.417 90.0824 549.728 89.6605 549.304 88.8167L538.975 67.7433H538.848L535.034 87.1081C534.631 89.0909 533.413 90.0824 531.379 90.0824H525.467Z" class="go" fill="#082D3E"/>
        <path d="M620.433 72.5845C620.433 73.597 620.147 74.3775 619.575 74.926C619.003 75.4533 618.176 75.717 617.096 75.717H595.992C596.586 80.1469 598.736 82.3618 602.444 82.3618C604.351 82.3618 605.951 81.6446 607.243 80.2101C608.176 79.1976 609.277 78.6913 610.549 78.6913H615.761C616.99 78.6913 617.901 78.9867 618.494 79.5773C619.088 80.1469 619.204 80.8957 618.844 81.8239C617.721 84.7349 615.75 86.9709 612.932 88.5319C610.136 90.0929 606.682 90.8734 602.571 90.8734C596.914 90.8734 592.359 89.207 588.905 85.874C585.473 82.5411 583.756 78.1112 583.756 72.5845C583.756 67.2054 585.462 62.881 588.873 59.6113C592.285 56.3206 596.798 54.6752 602.412 54.6752C607.837 54.6752 612.191 56.3206 615.475 59.6113C618.78 62.881 620.433 67.2054 620.433 72.5845ZM596.183 68.5976H608.388C608.176 66.8679 607.551 65.5389 606.512 64.6107C605.495 63.6615 604.139 63.1869 602.444 63.1869C599.054 63.1869 596.967 64.9904 596.183 68.5976Z" class="go" fill="#082D3E"/>
        <path d="M640.074 91C635.371 91 631.62 89.3441 628.824 86.0322C626.048 82.7204 624.66 78.2273 624.66 72.5528C624.66 67.1737 626.111 62.8283 629.014 59.5164C631.938 56.2046 635.773 54.5487 640.519 54.5487C642.659 54.5487 644.566 54.9705 646.24 55.8143C647.914 56.6581 649.164 57.8394 649.991 59.3582H650.436L650.181 55.4663V44.803C650.181 43.7272 650.467 42.9045 651.039 42.3349C651.612 41.7654 652.438 41.4806 653.518 41.4806H658.826C659.907 41.4806 660.733 41.7654 661.305 42.3349C661.877 42.9045 662.163 43.7272 662.163 44.803V86.76C662.163 87.8358 661.877 88.6585 661.305 89.2281C660.733 89.7976 659.907 90.0824 658.826 90.0824H655.457C653.55 90.0824 652.3 89.1648 651.707 87.3296L651.071 85.3361H650.626C649.588 87.1713 648.158 88.5741 646.336 89.5445C644.535 90.5148 642.448 91 640.074 91ZM643.666 81.4125C645.806 81.4125 647.501 80.6215 648.751 79.0394C650.001 77.4573 650.626 75.2951 650.626 72.5528C650.626 70.0004 650.001 67.9648 648.751 66.446C647.522 64.9061 645.859 64.1361 643.761 64.1361C641.494 64.1361 639.757 64.8533 638.549 66.2878C637.362 67.7222 636.769 69.8105 636.769 72.5528C636.769 75.4217 637.352 77.6155 638.517 79.1343C639.704 80.6531 641.42 81.4125 643.666 81.4125Z" class="go" fill="#082D3E"/>
        <path d="M681.487 49.8657C680.131 51.2157 678.457 51.8907 676.465 51.8907C674.474 51.8907 672.831 51.2263 671.539 49.8973C670.247 48.5473 669.6 46.8597 669.6 44.8346C669.6 42.9361 670.278 41.3224 671.634 39.9934C672.99 38.6645 674.654 38 676.624 38C678.637 38 680.29 38.6539 681.582 39.9618C682.896 41.2486 683.553 42.8623 683.553 44.803C683.553 46.807 682.864 48.4945 681.487 49.8657ZM673.923 90.0824C672.842 90.0824 672.016 89.7976 671.444 89.2281C670.872 88.6585 670.586 87.8358 670.586 86.76V58.7887C670.586 57.7128 670.872 56.8901 671.444 56.3206C672.016 55.751 672.842 55.4663 673.923 55.4663H679.199C680.279 55.4663 681.105 55.751 681.678 56.3206C682.271 56.8901 682.567 57.7128 682.567 58.7887V86.76C682.567 87.8358 682.271 88.6585 681.678 89.2281C681.105 89.7976 680.279 90.0824 679.199 90.0824H673.923Z" class="go" fill="#082D3E"/>
        <path d="M700.238 90.8734C696.276 90.8734 693.204 89.9875 691.022 88.2155C688.839 86.4436 687.748 83.9439 687.748 80.7164C687.748 76.9405 689.125 74.156 691.88 72.363C694.634 70.5489 698.935 69.6418 704.783 69.6418H710.695V66.3194C710.695 64.2099 709.18 63.1552 706.15 63.1552C705.006 63.1552 704.084 63.3134 703.385 63.6299C702.707 63.9252 702.145 64.4842 701.7 65.3069C701.489 65.7077 701.287 66.0241 701.097 66.2561C700.906 66.4882 700.599 66.7096 700.175 66.9206C699.751 67.1315 699.232 67.237 698.618 67.237H693.119C691.954 67.237 691.074 66.9206 690.481 66.2878C689.888 65.6549 689.729 64.8111 690.005 63.7564C690.767 60.8032 692.526 58.5566 695.28 57.0167C698.035 55.4557 701.637 54.6752 706.086 54.6752C711.299 54.6752 715.356 55.7721 718.259 57.966C721.162 60.1387 722.613 63.2291 722.613 67.237V83.7857C722.613 84.7349 722.698 85.6314 722.867 86.4752C723.1 87.6987 723.026 88.6058 722.645 89.1964C722.264 89.7871 721.49 90.0824 720.325 90.0824H716.225C714.127 90.0824 712.909 89.3757 712.57 87.9624C712.4 87.2874 712.316 86.5174 712.316 85.6525V85.526H711.871C709.943 89.0909 706.065 90.8734 700.238 90.8734ZM703.862 83.1845C705.896 83.1845 707.527 82.5516 708.756 81.286C710.006 80.0203 710.631 78.3855 710.631 76.3815V76.2233H704.783C703.046 76.2233 701.785 76.5081 701.001 77.0776C700.238 77.6472 699.857 78.5648 699.857 79.8304C699.857 82.0665 701.192 83.1845 703.862 83.1845Z" class="go" fill="#082D3E"/>
      </svg>

    </Link>
    <div className='hdr'>
      <div className='hdr__container container'>
        <ul
          className={`${
            isActive
              ? "hdr__nav toggle nav active"
              : "hdr__nav toggle nav"
          }`}
        >
          {menu.map((link) => (
            <li
              className={
                link.submenu && link.submenu.length > 0
                  ? "hdr__nav-li nav-item hdr__submenu-parent"
                  : "hdr__nav-li nav-item"
              }
              key={link.name}
            >
              <a
                className={"hdr__nav-link nav-link headm " + link.class}
                href={link.path}
                onClick={handleToggle}
              >
                {link.name}
              </a>
              {link.submenu && link.submenu.length > 0 ? (
                <ul className="hdr__submenu">
                  {link.submenu.map((sublink) => (
                    <li className="hdr__submenu-item" key={sublink.name}>
                      <a
                        className={"hdr__nav-link nav-link " + sublink.class}
                        href={sublink.path}
                        onClick={handleToggle}
                      >
                        {sublink.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>

        <button
          className={`${
            isActive
              ? "app hdr__menu toggle active"
              : " app hdr__menu toggle"
          }`}
          onClick={handleToggle}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Header;
